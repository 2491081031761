import React from "react";

export const Header = (props) => {
  const introStyle = {
    display: "table",
    width: "100%",
    padding: "0",
    background: "url(../img/gallery/gallery-2.jpg) center center no-repeat",
    backgroundColor: "#e5e5e5",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    backgroundSize: "cover",
    OBackgroundSize: "cover",
  };

  const overlayStyle = {
    background: "rgba(0, 0, 0, 0.2)",
  };

  const introTextStyle = {
    paddingTop: "350px",
    paddingBottom: "200px",
    textAlign: "center",
  };

  const headingStyle = {
    fontFamily: "'Raleway', sans-serif",
    color: "#fff",
    fontSize: "82px",
    fontWeight: "700",
    textTransform: "uppercase",
    marginTop: "0",
    marginBottom: "10px",
  };

  const spanStyle = {
    fontWeight: "800",
    color: "#5ca9fb",
  };

  const paragraphStyle = {
    color: "#fff",
    fontSize: "22px",
    fontWeight: "300",
    lineHeight: "30px",
    margin: "0 auto",
    marginBottom: "60px",
  };

  const introParagraphStyle = {
    margin: "12px 0 0",
    lineHeight: "24px",
  };

  return (
    <header id="header">
      <div className="intro" style={introStyle}>
        <div className="overlay" style={overlayStyle}>
          <div className="container">
            <div className="row">
              <div
                className="col-md-8 col-md-offset-2 intro-text"
                style={introTextStyle}
              >
                <h1 style={headingStyle}>
                  puwakhola-one hydropower
                  <span style={spanStyle}></span>
                </h1>
                <p style={{ ...paragraphStyle, ...introParagraphStyle }}>
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                <a
                  href="#about"
                  className="btn btn-custom btn-lg page-scroll"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    padding: "10px 20px",
                    backgroundColor: "#5ca9fb",
                  }}
                >
                  Read More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
